import { passwordPolicy } from '@/utils/constants'

const emailRegex =
  /^((?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)]))$/
const phoneRegex = /^[\d.\s\-+]+$/
const onlyNumbersRegex = /^\d+$/

export default function form(formData) {
  const data = {}

  Object.entries(formData).forEach((entry) => {
    const [field, values] = entry
    data[field] = { value: null, valid: true, empty: false, ...values }
  })

  function validateField(field, isOnSubmit = false) {
    const { value: fieldValue, validation, strength } = data[field]
    const { minimumValidStrength, minimumCharacterLength } = passwordPolicy
    const value = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue
    let isValid = true

    switch (validation) {
      case 'required':
        isValid = !!value
        break
      case 'email':
        isValid = !!value && emailRegex.test(value)
        break
      case 'age':
        isValid = !!value && validateAge(value)
        break
      case 'expiration':
        isValid = !!value && validateExpiration(value)
        break
      case 'phoneNumber':
        isValid = !!value && phoneRegex.test(value)
        break
      case 'postcode':
        isValid = !!value && onlyNumbersRegex.test(value)
        break
      case 'passwordStrength':
        isValid = !!value && strength >= minimumValidStrength && value.length >= minimumCharacterLength
        break
      case 'onSubmit':
        if (isOnSubmit) {
          isValid = !!value
        }
        break
      default:
        isValid = true
        break
    }

    data[field].valid = isValid
    data[field].empty = !value
  }

  function validateAge(dob) {
    const today = new Date()
    const month = today.getMonth() - new Date(dob).getMonth()
    let age = today.getFullYear() - new Date(dob).getFullYear()

    if (month < 0 || (month === 0 && today.getDate() < new Date(dob).getDate())) {
      age = age - 1
    }

    return age >= 16
  }

  function validateExpiration(mmYY) {
    const today = new Date()
    const currentYear = today.getFullYear().toString().substring(2)
    const [mm = '', yy = ''] = mmYY.split('/')
    const month = Number.parseInt(mm, 10)
    const year = Number.parseInt(yy, 10)
    const isMonthValid = !isNaN(month) && month >= 1 && month <= 12
    const isYearValid = !isNaN(year) && year >= currentYear && year <= 99
    let isValid = false

    if (isMonthValid && isYearValid) {
      const today = new Date()
      const expirationDate = new Date()

      expirationDate.setFullYear(year + 2000, month, 0)

      if (expirationDate >= today) {
        isValid = true
      }
    }

    return isValid
  }

  function validate(scrollToFirstError = false) {
    Object.keys(data).forEach((field) => {
      validateField(field, true)
    })

    if (scrollToFirstError) {
      const firstFieldWithErrorName = Object.entries(data).find((entry) => !entry[1].valid)?.[0]

      if (firstFieldWithErrorName) {
        const firstFieldWithError = document.querySelector(`[name="${firstFieldWithErrorName}"]`)

        if (firstFieldWithError) {
          setTimeout(() => {
            const isOnOverlay = !!document.querySelector('[data-id="overlay-container"]')
            const headerOffset = document.querySelector('header')?.offsetHeight || 0
            const yOffset = isOnOverlay ? 0 : headerOffset
            const { y: fieldY, height: fieldHeight } = firstFieldWithError.getBoundingClientRect()

            window.scrollBy(0, fieldY - fieldHeight - yOffset)
          }, 200)
        }
      }
    }
  }

  function isValid() {
    return Object.values(data).every((field) => field.valid)
  }

  function getValues() {
    const values = {}

    Object.entries(data).forEach((entry) => {
      const [field, object] = entry
      const { value = '' } = object

      values[field] = typeof value === 'string' ? value.trim() : value
    })

    return values
  }

  return { ...data, validateField, validate, isValid, getValues }
}
