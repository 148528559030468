
import form from '@/utils/form'
import rememberMe from '@/mixins/remember-me'
import InputField from '@/components/InputField'
import HeroButton from '@/components/HeroButton'
import Message from '@/components/Message'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  components: {
    HeroButton,
    InputField,
    LoadingSpinner,
    Message,
  },
  mixins: [rememberMe],
  props: {
    widerDesktopInputs: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('loginForm.login')
      },
    },
    subtitle: {
      type: String,
      default() {
        return this.$t('loginForm.subtitle')
      },
    },
    updateSuccess: {
      type: Boolean,
      default: false,
    },
    sessionExpired: {
      type: Boolean,
      default: false,
    },
    doubleoptinConsent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      serverError: false,
      form: form({
        email: { validation: 'email' },
        password: { validation: 'required' },
      }),
      rememberMe: false,
      isLoading: false,
    }
  },
  methods: {
    validate(field) {
      this.form.validateField(field)
    },
    async submit() {
      this.serverError = false
      this.$store.commit('SET_SESSION_EXPIRED', false)

      this.form.validate()

      if (this.form.isValid()) {
        if (this.isLoading) {
          return
        }

        this.isLoading = true
        this.$emit('login-submitted')
        this.$log({
          name: 'LoginAttempt',
          properties: { location: 'LoginForm', rememberMe: this.rememberMe, userAgent: navigator?.userAgent },
        })

        try {
          const { email, password } = this.form.getValues()

          this.serverError = false
          await this.$auth.loginWith('local', { data: { email, password } })

          this.$auth.$storage.setCookie('rememberMe', this.rememberMe)
          this.updateCookieExpiration()

          await this.logSuccessfulLogin()
          this.$emit('login-successful', () => this.stopIsLoading)
        } catch (error) {
          this.serverError = true
          await this.logFailedLogin()
          this.$emit('login-failed')
          this.stopIsLoading()
        }
      } else {
        this.serverError = true
      }
    },
    stopIsLoading() {
      this.isLoading = false
    },
    logSuccessfulLogin() {
      const paidOrders = this.$auth.user.paidOrderCount
      const { fullPath } = this.$route
      let page = this.$route.name

      if (page === 'login') page += '_page'

      this.$gtm.push({
        event: 'userLogin',
        location: page,
        status: 'success',
        paidOrders,
      })

      this.$log({
        name: 'LoginSuccess',
        properties: { location: 'LoginForm', rememberMe: this.rememberMe, userAgent: navigator?.userAgent, fullPath },
      })
    },
    logFailedLogin() {
      let page = this.$route.name
      if (page === 'login') page += '_page'

      this.$gtm.push({
        event: 'userLogin',
        location: page,
        status: 'fail',
      })
    },
  },
}
