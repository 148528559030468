
import CloseIcon from '@/static/svg/nav/close-icon.svg'
import InfoIcon from '@/static/svg/prescription/info-icon.svg'
import TickIcon from '@/static/svg/prescription/tick-icon.svg'

export default {
  components: {
    CloseIcon,
    InfoIcon,
    TickIcon,
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    scrollsOnAppear: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
    }
  },
  mounted() {
    if (this.scrollsOnAppear) {
      this.$nextTick(() => this.$el.scrollIntoView({ behavior: 'smooth', block: 'center' }))
    }
  },
  methods: {
    hide(e) {
      if (e.animationName === 'fadeOut') {
        this.show = false
        this.$emit('collapsed')
      }
    },
    closeMessage() {
      this.$emit('closed')
    },
  },
}
